<template>
  <div>
    <select
      v-if="features.productVariations"
      v-model="selectedVariationId"
    >
      <option
        v-for="variation in variations"
        :key="variation.id"
        :value="variation.id"
      >
        {{ variation.name }}
      </option>
    </select>
    <div class="size-full">
      <div v-if="productLoading || temporalStock > 0">
        <Transition mode="out-in">
          <button
            v-if="!added"
            class="flex h-16 w-full items-center justify-center gap-2  bg-custom-800 px-3 text-white  md:h-12 xl:h-14"
            @click.prevent="addToCart(selectedVariationId)"
          >
            <v-icon icon="mdi:cart" />
            <p>
              Agregar al carrito
            </p>
          </button>
          <div
            v-else
            class="flex h-16 w-full items-center justify-center gap-2 border-4 border-custom-800 bg-white
        px-3 text-center text-sm font-black text-custom-800
        md:h-12 xl:h-14"
          >
            <v-icon icon="mdi:cart-check" />
            <p>
              AGREGADO
            </p>
          </div>
        </Transition>
      </div>
      <div
        v-else
        class="flex h-16 w-full items-center justify-center gap-2 border-4 border-custom-800 bg-custom-100
        px-3 text-center text-sm font-black text-custom-800
        md:h-12 xl:h-14"
      >
        <v-icon icon="mdi:cart-off" />
        <p v-if="product.stock == 0">
          AGOTADO
        </p>
        <p v-else-if="temporalStock == 0">
          NO QUEDAN MÁS
        </p>
      </div>
    </div>
    <a
      v-if="showMessageWhenNoStock && product?.stock == 0"
      class="mt-4 flex items-center justify-center gap-1 text-sm font-medium text-custom-800"
      :href="`mailto:contacto@libroschile.cl?subject=${encodeURI('Encargo de ' + product?.name + ' agotado')}`"
    >
      <v-icon icon="mdi:alert-circle" />
      Ups, no nos quedan, <span class="underline"> escríbenos </span> para encargarlo.
    </a>
  </div>
</template>
<script>
import { useCartStore } from '@/stores/cart';
import productsApi from '@/api/store/products';
import { useEventBus } from '@vueuse/core';

export default {
  props: {
    productId: {
      type: Number,
      required: true,
    },
    showMessageWhenNoStock: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const cartStore = useCartStore();

    return {
      cartStore,
    };
  },
  data() {
    return {
      variations: [],
      product: null,
      selectedVariationId: null,
      added: false,
      addedTimeout: null,
      temporalStock: 0,
      productLoading: true,
      showCartBus: useEventBus('cart.show'),
    };
  },
  async mounted() {
    this.product = (await productsApi.show(this.productId, {
      withVariations: true,
      withStock: true,
    })).data.data;

    this.variations = this.product.variations;

    if (! this.features.productVariations) {
      this.selectedVariationId = this.variations[0].id;
    }
    this.temporalStock =
      this.product.stock
      - this.cartStore.getCountOfVariation(this.product.id, this.selectedVariationId);

    this.productLoading = false;
  },
  methods: {
    addToCart(id) {
      if (this.productLoading) {
        return;
      }

      if (this.product.stock <= 0) {
        return;
      }

      this.temporalStock -= 1;
      this.added = true;
      this.cartStore.addProductVariationById(id);

      setTimeout(() => {
        this.showCartBus.emit();
      }, 700);

      this.addedTimeout = setTimeout(() => {
        this.added = false;
      }, 3000);
    },
  },
};

</script>

<style>
.v-enter-active,
.v-leave-active {
  @apply transition;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
