<template>
  <div class="flex flex-col gap-5 rounded-lg bg-white p-6 text-black text-opacity-80 shadow dark:bg-gray-800 dark:text-white dark:text-opacity-80 dark:shadow-none">
    <div class="flex flex-row items-center">
      <h3 class="my-1 font-medium">
        carrusel
      </h3>
      <div class="ml-auto flex items-center gap-2">
        <form
          ref="uploadImagesForm"
          method="POST"
          :action="`/admin/products/${productId}/upload-images`"
          class="flex items-center justify-center rounded border p-0 pr-3 dark:border-gray-500"
        >
          <input
            type="hidden"
            name="_token"
            :value="crsf"
          >
          <input
            class="file:bg-dark file:h-10 file:w-40 file:rounded
              file:border-0 file:bg-opacity-10 file:px-2
              file:focus:border-2 file:focus:border-blue-500 file:focus:ring-0
              disabled:text-white disabled:text-opacity-60
              file:dark:bg-black file:dark:bg-opacity-30"
            type="file"
            name="images[]"
            multiple
          >
          <button
            type="button"
            class="flex items-center gap-2"
            @click="uploadImage "
          >
            <v-icon
              icon="mdi:upload"
            />
            Subir imágenes
          </button>
        </form>
        <button
          class="flex items-center gap-2 rounded border px-3 py-2 dark:border-gray-500"
          type="button"
          @click="updateImages"
        >
          <v-icon icon="mdi:content-save" />
          Guardar
        </button>
      </div>
    </div>
    <div v-if="!loading">
      <draggable
        v-model="images"
        item-key="uuid"
        class="flex w-full flex-col items-start"
        @start="drag=true"
        @end="drag=false"
      >
        <template #item="{element, index}">
          <div class="relative w-full p-3">
            <div class="grid grid-cols-12 items-center justify-center gap-5 border p-3">
              <img
                :src="element.originalUrl"
                :class="`max-h-28 col-span-6 mx-auto  bg-cover ${element.deleted ? 'opacity-10' : ''}`"
              >
              <div class="col-span-6">
                <span class="text-xs text-black text-opacity-50">
                  enlace
                </span>
                <input
                  v-model="element.href"
                  type="text"
                  :class="`rounded h-10 px-2 w-full
                                focus:border-blue-500 focus:border-2 focus:ring-0
                                disabled:text-opacity-60 disabled:text-white
                                border-black border-opacity-20 border-1
                                dark:border-white dark:border-opacity-10 dark:border-1
                                bg-dark bg-opacity-10
                                dark:bg-black dark:bg-opacity-30  ${element.deleted ? 'opacity-10' : ''}`"
                >
              </div>
            </div>
            <div
              v-if="element.deleted !== true"
              class="absolute right-0 top-0 flex size-6 items-center justify-center rounded-full bg-red-500 text-white drop-shadow-xl"
              @click="removeImage(index)"
            >
              <Icon icon="mdi:close" />
            </div>
            <div
              v-else
              class="absolute right-0 top-0 flex size-full flex-col items-center justify-center gap-2"
              @click="restoreImage(index)"
            >
              <Icon icon="mdi:restore" />
              deshacer
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div v-else>
      loading
    </div>
  </div>
</template>
<script>

import draggable from 'vuedraggable';
import { Icon } from '@iconify/vue';

import customizationApi from '@/api/admin/customization';

export default {
  components: {
    draggable,
    Icon,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    crsf: {
      type: String,
      default: '',
    },
    customizationKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      images: [],
      drag: false,
      loading: true,
    };
  },
  mounted() {
    this.getImages();
  },
  methods: {
    removeImage(index) {
      this.images[index].deleted = true;
    },
    restoreImage(index) {
      this.images[index].deleted = false;
    },
    async getImages() {
      const response = await customizationApi.getImages(this.customizationKey);
      this.images = Object.values(response.data).map((image) => ({
        ...image,
        deleted: false,
        href: image.customProperties.href || '',
      }));

      this.loading = false;
    },
    async uploadImage() {
      this.loading = true;
      const formData = new FormData(this.$refs.uploadImagesForm);

      await customizationApi.uploadImages(this.customizationKey, formData);

      this.getImages();
    },
    async updateImages() {
      this.loading = true;

      const images = this.images.map((image) => {
        const { uuid, deleted = false } = image;
        return {
          uuid,
          deleted,
          href: image.href,
        };
      });

      await customizationApi.updateImages(this.customizationKey, images);

      this.getImages();
    },
  },
};
</script>
