
import api from '../index';

export default {
  uploadImages(key, formData) {
    return api({
      method: 'post',
      url: `/admin/customization-data/images`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      params: {
        key,
      },
    });
  },
  updateImages(key, formData) {
    return api({
      method: 'put',
      url: `/admin/customization-data/images`,
      data: formData,
      params: {
        key,
      },
    });
  },
  getImages(key) {
    return api({
      method: 'get',
      url: `/admin/customization-data/images`,
      params: {
        key,
      },
    });
  },
};
