import api from '../index';

export default {
  getMethods(address, communeId, regionId, shippingMethod) {
    return api({
      method: 'get',
      url: '/api/store/payment/methods',
      params: {
        address,
        communeId,
        regionId,
        shippingMethod,
      },
    });
  },
  getCost(paymentMethod) {
    return api({
      method: 'get',
      url: '/api/store/payment/cost',
      params: {
        paymentMethod,
      },
    });
  },
};
