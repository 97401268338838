<template>
  <div>
    <div
      v-if="cart.syncing"
      class="flex size-full  items-center justify-center gap-2 p-4 text-custom-800"
    >
      <v-icon
        icon="mdi:loading"
        class="animate-spin"
      />
      Cargando
    </div>

    <div
      v-else
      class="flex flex-col divide-y-2 divide-gray-200 dark:divide-gray-700"
    >
      <div
        v-for="product in isFromOrder ? cartFromOrder : cart.products"
        :key="product.id"
        class="flex flex-col px-3 py-6 sm:px-6 "
      >
        <div
          v-for="variation in product.variations "
          :key="variation.id"
          class="flex items-center gap-3 sm:gap-7 "
        >
          <button
            v-if="!viewOnly"
            @click="cart.removeProductVariationById(variation.id)"
          >
            <v-icon
              icon="mdi:delete-outline"
              class="text-lg"
            />
          </button>
          <div class="flex w-full items-center gap-3">
            {{ product.name }}
            <span
              v-if="product.oversized"
              class="rounded bg-gray-100 px-3 py-1 text-xs"
              title="Este producto es mas grande de lo habitual, debes contactarnos para coordinar el envío"
            >
              sobredimensionado
            </span>
          </div>
          <div class="w-6 font-semibold">
            {{ variation.quantity }}
          </div>
          <v-currency
            class="w-24 text-end font-semibold"
            :amount="variation.quantity * variation.price"
          />
          <div
            v-if="showTotal"
            class="sm:w-28"
          />
        </div>
      </div>
      <div
        v-if="shippingCost"
        class="flex flex-col px-3 py-6 sm:px-6 "
      >
        <div
          class="flex items-center gap-3 sm:gap-7 "
        >
          <div class="w-full">
            Envío
          </div>
          <div class="w-6 font-semibold" />
          <v-currency
            class="w-24 text-end font-semibold"
            :amount="shippingCost"
          />
          <div
            v-if="showTotal"
            class="sm:w-28"
          />
        </div>
      </div>

      <div
        v-if="showTotal"
        class="flex w-full justify-end py-8 pr-6"
      >
        <p class="font-semibold">
          Total
        </p>
        <div class="w-24 text-end">
          <v-currency
            class="w-24 text-end font-semibold"
            :amount="totalPrice"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useCartStore } from "@/stores/cart";

export default {
  props: {
    showTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    productsFromOrder: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    shippingCost: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup() {
    return {
      cart: useCartStore(),
    };
  },
  data() {
    return {
      cartFromOrder: {},
    };
  },
  computed: {
    isFromOrder() {
      return Object.keys(this.productsFromOrder).length > 0;
    },
    totalPrice() {
      return Object.values(this.isFromOrder ? this.cartFromOrder : this.cart.products).reduce((acc, product) => {
        return acc + Object.values(product.variations).reduce((acc, variation) => {
          return acc + variation.quantity * variation.price;
        }, 0);
      }, 0) + this.shippingCost;
    },
  },
  mounted() {
    if (this.isFromOrder) {
      this.cartFromOrder = this.fromOrderToCart();
    } else {
      this.updateCart();
    }

    if (location.href.includes('/order/')) {
      this.cart.clear();
    }
  },
  methods: {
    async updateCart() {
      this.cart.sync();
    },
    fromOrderToCart() {
      return Object.values(this.productsFromOrder).reduce((acc, variation) => {
        acc[variation.product_id] = {
          id: variation.product_id,
          name: variation.product.name,
          price: variation.price,
          variations: {
            [variation.id]: {
              id: variation.id,
              quantity: variation.pivot.quantity,
              price: variation.pivot.unit_price,
            },
          },
        };
        return acc;
      }, {});
    },
  },
};

</script>
