<template>
  <div class="bg-black text-white" />
  <div class="relative size-full ">
    <div class="absolute left-0 top-0 z-10 flex h-full items-center 2xl:px-3">
      <v-icon
        icon="mdi:chevron-left"
        class="z-20 cursor-pointer text-4xl"
        @click="prev"
      />
    </div>
    <div
      class="left-0 top-0 flex size-full justify-center"
    >
      <a
        :href="carouselImage?.custom_properties?.href"
        target="_blank"
      >
        <img
          key="carouselImage"
          :src="carouselImage.original_url"
          class="h-full max-h-60 object-contain object-center sm:max-h-none"
        >
      </a>
    </div>
    <div class="absolute right-0 top-0 z-10 flex h-full  items-center 2xl:px-3">
      <v-icon
        icon="mdi:chevron-right"
        class="z-20 cursor-pointer text-4xl"
        @click="next"
      />
    </div>
  </div>
</template>
<script>


export default {
  name: 'Carousel',
  props: {
    images: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
      imageList: Object.values(this.images),
    };
  },
  computed: {
    carouselImage() {
      return this.imageList[this.activeIndex];
    },
  },
  methods: {
    next() {
      if (this.activeIndex < this.imageList.length - 1) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0;
      }
    },
    prev() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex = this.imageList.length - 1;
      }
    },
  },
};
</script>
