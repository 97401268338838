<template>
  <div
    v-if="!cart.isCartNotEmpty"
    class="flex flex-col gap-3"
  >
    <span class="my-3 text-center font-medium">
      El carrito esta vacio
    </span>
    <a
      class="w-full bg-custom-900 p-4 text-2xl font-medium text-white"
      href="/"
    >
      Seguir comprando
    </a>
  </div>
  <div v-else>
    <div class="flex w-full flex-col gap-12 lg:w-[800px]">
      <div class="flex flex-col gap-5">
        <h2 class="text-3xl font-medium text-custom-900">
          Libros seleccionados
        </h2>
        <div class="border-2 border-custom-800  bg-white dark:bg-black">
          <cart-details :show-total="true" />
        </div>
      </div>
      <div class="flex flex-col gap-5">
        <h2 class="text-3xl font-medium text-custom-900">
          Información
        </h2>
        <div class="border-2 border-custom-800 p-2 sm:p-5">
          <form
            id="form-shop"
            class="flex flex-col gap-4"
            method="POST"
            action="/order"
          >
            <input
              type="hidden"
              name="_token"
              :value="crsfToken"
            >
            <input
              type="hidden"
              name="products"
              :value="JSON.stringify(cart.itemsToJson)"
            >
            <div class="grid grid-cols-12 items-center gap-2 bg-gray-50 p-3 sm:gap-4 sm:bg-transparent sm:p-0">
              <div class="col-span-12 font-medium sm:col-span-4 sm:text-end">
                Nombre
              </div>
              <div class="col-span-12 text-end sm:col-span-5">
                <input
                  class=" h-8 w-full rounded-sm border border-gray-500 focus:ring-custom-800"
                  type="text"
                  name="name"
                  required
                >
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-2 bg-gray-50 p-3 sm:gap-4 sm:bg-transparent sm:p-0">
              <div class="col-span-12 font-medium sm:col-span-4 sm:text-end">
                Correo
              </div>
              <div class="col-span-12 text-end sm:col-span-5">
                <input
                  class=" h-8 w-full rounded-sm border border-gray-500 focus:ring-custom-800"
                  type="email"
                  name="email"
                  required
                >
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-2 bg-gray-50 p-3 sm:gap-4 sm:bg-transparent sm:p-0">
              <div class="col-span-12 flex items-center gap-2  font-medium sm:col-span-4 sm:justify-end">
                <p>
                  Número de telefono
                </p>
              </div>
              <div class="col-span-12 text-end sm:col-span-5">
                <input
                  class=" h-8 w-full rounded-sm border border-gray-500 focus:ring-custom-800"
                  type="tel"
                  name="phone"
                  required
                >
              </div>
            </div>
            <div class="h-1" />
            <div class="grid grid-cols-12 items-center gap-2 bg-gray-50 p-3 sm:gap-4 sm:bg-transparent sm:p-0">
              <div class="col-span-12 font-medium sm:col-span-4 sm:text-end">
                Región
              </div>
              <div class="col-span-12 text-end sm:col-span-5">
                <select
                  v-model="form.regionId"
                  class="h-8 w-full rounded-sm border border-gray-500 px-3 py-0 focus:ring-custom-800"
                  name="region_id"
                  required
                >
                  <option
                    value=""
                    disabled
                    selected
                  >
                    Seleccione una región
                  </option>
                  <option
                    v-for="region in regions"
                    :key="region.id"
                    :value="region.id"
                  >
                    {{ region.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-2 bg-gray-50 p-3 sm:gap-4 sm:bg-transparent sm:p-0">
              <div class="col-span-12 font-medium sm:col-span-4 sm:text-end">
                Comuna
              </div>
              <div class="col-span-12 text-end sm:col-span-5">
                <select
                  v-model="form.communeId"
                  class="h-8 w-full rounded-sm border border-gray-500 px-3 py-0 focus:ring-custom-800 disabled:text-gray-500"
                  name="commune_id"
                  :disabled="form.regionId === ''"
                  required
                >
                  <option
                    value=""
                    disabled
                    selected
                    class="text-gray-500"
                  >
                    Seleccione una comuna
                  </option>
                  <option
                    v-for="commune in communesFiltered"
                    :key="commune.id"
                    :value="commune.id"
                  >
                    {{ commune.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-2 bg-gray-50 p-3 sm:gap-4 sm:bg-transparent sm:p-0">
              <div class="col-span-12 font-medium sm:col-span-4 sm:text-end">
                Dirección de despacho
              </div>
              <div class="col-span-12 text-end sm:col-span-5">
                <input
                  v-model="form.address"
                  class="h-8 w-full rounded-sm border border-gray-500 focus:ring-custom-800"
                  type="text"
                  name="address"
                  required
                >
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-2 bg-gray-50 p-3 sm:gap-4 sm:bg-transparent sm:p-0">
              <div class="col-span-12 font-medium sm:col-span-4 sm:text-end">
                Método de despacho
              </div>
              <div class="col-span-12 sm:col-span-5">
                <div class="flex flex-col gap-2 sm:bg-gray-50 sm:p-3">
                  <label
                    v-for="shipping in shippingMethods"
                    :key="shipping.id"
                    :class="`flex items-center gap-2
                    ${!availableShippingMethods.includes(shipping.value) ? 'text-gray-500' : ''}`"
                  >
                    <input
                      v-model="form.shippingMethod"
                      type="radio"
                      name="shipping_method"

                      class="checked:bg-custom-800 focus:bg-custom-800 focus:text-custom-800 focus:ring-custom-800 disabled:bg-gray-100"
                      :value="shipping.value"
                      :disabled="!availableShippingMethods.includes(shipping.value)"
                      required
                    >
                    {{ shipping.label }}
                  </label>
                  <div v-if="availableShippingMethodsMessage" class="text-sm text-custom-400 ">
                    {{ shippingMethodsMessageToShow }}
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-2 bg-gray-50 p-3 sm:gap-4 sm:bg-transparent sm:p-0">
              <div class="col-span-12 font-medium sm:col-span-4 sm:text-end">
                Método de pago
              </div>
              <div class="col-span-12 text-end sm:col-span-5">
                <div class="flex flex-col gap-2 sm:bg-gray-50 sm:p-3">
                  <label
                    v-for="payment in paymentMethods"
                    :key="payment.id"
                    :class="`flex items-center gap-2
                    ${!availablePaymentMethods.includes(payment.value) ? 'text-gray-500' : ''}`"
                  >
                    <input
                      v-model="form.paymentMethod"
                      type="radio"
                      name="payment_method"
                      class="checked:bg-custom-800 focus:bg-custom-800 focus:text-custom-800 focus:ring-custom-800 disabled:bg-gray-100"
                      :value="payment.value"
                      :disabled="!availablePaymentMethods.includes(payment.value)"
                      required
                    >
                    {{ payment.label }}
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="flex flex-col gap-5">
        <h2 class="text-3xl font-medium text-custom-900">
          Resumen
        </h2>
        <div class="border-2 border-custom-800  bg-white dark:bg-black">
          <div class="flex flex-col divide-y-2 divide-gray-200 dark:divide-gray-700">
            <div
              class="flex flex-col px-3 py-6 sm:px-6 "
            >
              <div
                class="flex items-center gap-3 sm:gap-7 "
              >
                <v-icon
                  icon="mdi:bookshelf"
                  class="text-3xl text-custom-800"
                />
                <div class="w-full">
                  Libros
                </div>
                <v-currency
                  class="w-24 text-end font-semibold"
                  :amount="cart.totalAmount"
                />
                <div
                  v-if="true"
                  class="sm:w-28"
                />
              </div>
            </div>
            <div
              class="flex flex-col px-3 py-6 sm:px-6 "
            >
              <div
                class="flex items-center gap-3 sm:gap-7 "
              >
                <v-icon
                  icon="mdi:local-shipping"
                  class="text-3xl"
                />
                <div class="w-full text-custom-800">
                  Envío
                </div>
                <v-currency
                  class="w-24 text-end font-semibold"
                  :amount="shippingCost"
                />
                <div
                  v-if="true"
                  class="sm:w-28"
                />
              </div>
            </div>
            <div
              v-if="true"
              class="flex w-full justify-end py-8 pr-6"
            >
              <p class="font-semibold">
                Total
              </p>
              <div class="w-24 text-end">
                <v-currency
                  class="w-24 text-end font-semibold"
                  :amount="totalAmount"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        form="form-shop"
        class="w-full bg-custom-900 p-4 text-2xl font-medium text-white
          disabled:bg-gray-100 disabled:text-gray-300"
        :disabled="!canSubmit"
      >
        Confirmar compra
      </button>
    </div>
  </div>
</template>
<script>

import { useCartStore } from "@/stores/cart";

import CartDetails from "@/components/cart/cart-details.vue";

import RegionsApi from "@/api/regions";
import CommunesApi from "@/api/communes";
import ShippingApi from "@/api/store/shipping";
import PaymentApi from "@/api/store/payment";

export default {
  components: {
    CartDetails,
  },
  props: {
    crsfToken: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
    old: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    shippingMethods: {
      type: Array,
      required: true,
    },
    paymentMethods: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      cart: useCartStore(),
    };
  },
  data() {
    return {
      regions: [],
      communes: [],
      form: {
        address: "",
        regionId: "",
        communeId: "",
        shippingMethod: "",
      },
      availableShippingMethods: [],
      availablePaymentMethods: [],
      availableShippingMethodsMessage: null,
      shippingCost: 0,
    };
  },
  computed: {
    communesFiltered() {
      return this.communes.filter(
        (commune) => commune.regionId === this.form.regionId,
      );
    },
    canSubmit() {
      return (
        this.form.regionId &&
        this.form.communeId &&
        this.form.address &&
        this.form.shippingMethod &&
        this.form.paymentMethod
      );
    },
    totalAmount() {
      return this.cart.totalAmount + this.shippingCost;
    },
    shippingMethodsMessageToShow() {
      const key = this.availableShippingMethodsMessage;

      if (key == 'oversized_items'){
        return `Uno de los libros en el carrito es muy grande para calcular el costo de envío.
          Luego de realizar la compra, nos pondremos en contacto contigo para coordinar el envío.`;
      }

      if (key == 'too_many_items'){
        return `El carrito tiene demasiados libros para calcular el costo de envío.
          Luego de realizar la compra, nos pondremos en contacto contigo para coordinar el envío.`;
      }

      return null;
    },
  },
  watch: {
    "form.regionId": function () {
      this.form.communeId = "";
      this.getShippingMethods();
    },
    "form.communeId": function () {
      this.getShippingMethods();
    },
    "form.shippingMethod": function () {
      this.form.paymentMethod = "";

      this.getPaymentMethods();
      this.getShippingCost();
    },
  },
  async mounted() {
    this.regions = (await RegionsApi.index()).data;
    this.communes = (await CommunesApi.index()).data;
  },
  methods: {
    async getShippingMethods() {
      if (!this.form.regionId || !this.form.communeId) {
        this.form.shippingMethod = "";
        this.availableShippingMethods = [];
        this.availableShippingMethodsMessage = null;

        return;
      }

      const data = (await ShippingApi.getMethods(
        this.form.address || "na",
        this.form.communeId,
        this.form.regionId,
        this.cart.itemsToJson,

      )).data;

      this.availableShippingMethods = data.shippingMethods;
      this.availableShippingMethodsMessage = data.message;
    },
    async getPaymentMethods() {
      if (!this.form.shippingMethod || !this.form.communeId) {
        this.form.paymentMethods = "";
        this.availablePaymentMethods = [];

        return;
      }

      this.availablePaymentMethods = (await PaymentApi.getMethods(
        this.form.address || "na",
        this.form.communeId,
        this.form.regionId,
        this.form.shippingMethod,
      )).data.paymentMethods;
    },
    async getShippingCost(){
      if (!this.form.shippingMethod || !this.form.communeId) {
        this.shippingCost = 0;

        return;
      }

      this.shippingCost = (await ShippingApi.getCost(
        this.form.shippingMethod,
        this.form.address || "na",
        this.form.communeId,
        this.form.regionId,
      )).data.shippingCost;
    },
  },
};

</script>
