import api from '../index';

export default {
  getMethods(address, communeId, regionId, products) {
    return api({
      method: 'get',
      url: '/api/store/shipping/methods',
      params: {
        address,
        communeId: communeId,
        regionId: regionId,
        products: products,
      },
    });
  },
  getCost(shippingMethod, address, communeId, regionId) {
    return api({
      method: 'get',
      url: '/api/store/shipping/cost',
      params: {
        shippingMethod,
        address,
        communeId,
        regionId,
      },
    });
  },
};
