<template>
  <div class="bg-white text-opacity-80 text-black shadow dark:bg-gray-800 dark:text-opacity-80 flex flex-col gap-5 dark:text-white dark:shadow-none p-6 rounded-lg">
    <div class="flex flex-row items-center">
      <h3 class="my-1 font-medium">
        images
      </h3>
      <div class="ml-auto flex items-center gap-2">
        <form
          ref="uploadImagesForm"
          method="POST"
          :action="`/admin/products/${productId}/upload-images`"
          class="flex rounded border dark:border-gray-500 items-center justify-center pr-3 p-0"
        >
          <input
            type="hidden"
            name="_token"
          :value="crsf"
          >
          <input
            class="file:rounded file:h-10 file:px-2 file:w-40
              file:focus:border-blue-500 file:focus:border-2 file:focus:ring-0
              disabled:text-opacity-60 disabled:text-white file:border-0
              file:bg-dark file:bg-opacity-10
              file:dark:bg-black file:dark:bg-opacity-30"
            type="file"
            name="images[]"
            multiple
          >
          <button
            type="button"
            class="flex gap-2 items-center"
            @click="uploadImage "
          >
            <v-icon
              icon="mdi:upload"
            />
            Subir imágenes
          </button>
        </form>
        <button
          class="rounded border dark:border-gray-500 flex gap-2 items-center px-3 py-2"
          type="button"
          @click="updateImages"
        >
          <v-icon icon="mdi:content-save" />
          Guardar
        </button>
      </div>
    </div>
    <div v-if="!loading">
      <draggable
        v-model="images"
        item-key="uuid"
        class="flex flex-wrap items-center"
        @start="drag=true"
        @end="drag=false"
      >
        <template #item="{element, index}">
          <div class="relative p-3">
            <img
              :src="element.originalUrl"
              :class="`max-h-28 rounded border-2 bg-cover ${element.deleted ? 'opacity-10' : ''}`"
            >
            <div
              v-if="element.deleted !== true"
              class="absolute bg-red-500 drop-shadow-xl rounded-full text-white flex items-center justify-center h-6 w-6 top-0 right-0"
              @click="removeImage(index)"
            >
              <Icon icon="mdi:close" />
            </div>
            <div
              v-else
              class="absolute flex flex-col gap-2 items-center justify-center h-full w-full top-0 right-0"
              @click="restoreImage(index)"
            >
              <Icon icon="mdi:restore" />
              deshacer
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div v-else>
      loading
    </div>
  </div>
</template>
<script>

import draggable from 'vuedraggable';
import { Icon } from '@iconify/vue';

import productsApi from '@/api/admin/products';

export default {
  components: {
    draggable,
    Icon,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    crsf: {
      type: String,
      default: '',
    },
    productId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      images: [],
      drag: false,
      loading: true,
    };
  },
  mounted() {
    this.getImages();
  },
  methods: {
    removeImage(index) {
      this.images[index].deleted = true;
    },
    restoreImage(index) {
      this.images[index].deleted = false;
    },
    async getImages() {
      const response = await productsApi.getImages(this.productId);
      this.images = Object.values(response.data);

      this.loading = false;
    },
    async uploadImage() {
      this.loading = true;
      const formData = new FormData(this.$refs.uploadImagesForm);

      await productsApi.uploadImages(this.productId, formData);

      this.getImages();
    },
    async updateImages() {
      this.loading = true;

      const images = this.images.map((image) => {
        const { uuid, deleted = false } = image;
        return {
          uuid,
          deleted,
        };
      });

      await productsApi.updateImages(this.productId, images);

      this.getImages();
    },
  },
};
</script>
